<template>
  <v-checkbox
    v-if="props.field.datatype === 'boolean'"
    v-model="fieldModel"
    :error-messages="getVuelidateErrors(v$.fieldModel.$errors)"
    :label="props.field.label + (props.field.required ? '*' : '')"
    :true-value="true"
    :false-value="false"
    density="compact"
    hide-details="auto"
    variant="outlined"
    @input="
      () => {
        v$.fieldModel.$validate();
        updateValueInOrder();
      }
    "
  />
  <template v-else-if="props.field.datatype === 'string'">
    <div class="text-subtitle-1 text-medium-emphasis">
      {{ props.field.label + (props.field.required ? "*" : "") }}
    </div>
    <v-textarea
      v-model="fieldModel"
      :error-messages="getVuelidateErrors(v$.fieldModel.$errors)"
      :placeholder="props.field.placeholder"
      density="compact"
      hide-details="auto"
      variant="outlined"
      @focusout="
        () => {
          v$.fieldModel.$validate();
          updateValueInOrder();
        }
      "
    />
  </template>
  <template v-else-if="props.field.datatype === 'integer'">
    <div class="text-subtitle-1 text-medium-emphasis">
      {{ props.field.label + (props.field.required ? "*" : "") }}
    </div>
    <v-text-field
      v-model="fieldModel"
      :error-messages="getVuelidateErrors(v$.fieldModel.$errors)"
      :placeholder="props.field.placeholder"
      density="compact"
      hide-details="auto"
      variant="outlined"
      @focusout="
        () => {
          v$.fieldModel.$validate();
          updateValueInOrder();
        }
      "
    />
  </template>
</template>

<script lang="ts" setup>
import { helpers, sameAs } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import {
  dutchDecimal,
  dutchInteger,
  dutchMaxLength,
  dutchMaxVal,
  dutchMinLength,
  dutchMinVal,
  dutchRequiredIf,
} from "~/validators/validators";
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";
import type {
  customField,
  customIntegerConfiguration,
  customStringConfiguration,
} from "~/composables/store/checkout";

const props = defineProps<{
  field: customField;
}>();

const optionsStore = useCheckoutStore();

onMounted(() => {
  fieldModel.value =
    optionsStore.order.customFields.find((field) => field.id === props.field.id)
      ?.value ?? "";
});

const fieldModel = ref<string | number | boolean>("");

const updateValueInOrder = () => {
  if (props.field.datatype == "integer") {
    const val = fieldModel.value?.toString() ?? "";
    fieldModel.value = isNaN(parseFloat(val))
      ? fieldModel.value
      : parseFloat(val);
  }

  optionsStore.setCustomField({
    id: props.field.id,
    value: fieldModel.value,
  });
};

const rules = computed(() => {
  // eslint-disable-next-line
  const result: any = {
    requiredIf: dutchRequiredIf(props.field.required),
  };

  if (props.field.datatype === "integer") {
    const configuration = props.field
      .configuration as customIntegerConfiguration;
    // result.numeric = numeric; // This makes negative values disallowed.
    if (configuration.decimal) {
      // TODO: Improve this to be the amount of decimals instead of yes or no decimals
      if (configuration.decimal === 0) {
        result.integer = dutchInteger;
      } else {
        result.decimal = dutchDecimal;
      }
    }

    if (configuration.min !== undefined) {
      result.minValue = dutchMinVal(configuration.min);
    }

    if (configuration.max !== undefined) {
      result.maxValue = dutchMaxVal(configuration.max);
    }
  }

  if (props.field.datatype === "string") {
    const configuration = props.field
      .configuration as customStringConfiguration;
    if (configuration.regex) {
      result.regex = helpers.regex(configuration.regex);
    }

    if (configuration.length?.min !== undefined) {
      result.minLength = dutchMinLength(configuration.length.min);
    }

    if (configuration.length?.max !== undefined) {
      result.maxLength = dutchMaxLength(configuration.length.max);
    }
  }

  if (props.field.datatype === "boolean") {
    // IGNORE
    // See TODO in type
    // If the required, it means that the field is required to be true.
    if (props.field.required) {
      result.assureChecked = helpers.withMessage(
        "Dit veld is verplicht.",
        sameAs(true),
      );
    }
  }

  return { fieldModel: result };
});

const v$ = useVuelidate(rules, { fieldModel });
</script>

<style scoped></style>
