import { defineStore } from "pinia";
import type { Ref } from "vue";
import { unref } from "vue";
import { decimal, helpers, minValue, sameAs } from "@vuelidate/validators";
import type {
  alert,
  customFieldDto,
  deliveryDay,
  deliverySlot,
  item,
  orderDTO,
  orderOptions,
  productDto,
} from "./index";
import {
  dutchEmail,
  dutchHouseNumber,
  dutchNumeric,
  dutchPostalCode,
  dutchRequired,
  dutchRequiredIf,
  dutchTelephone,
} from "~/validators/validators";
import { useAsyncData } from "#app";

export const useCheckoutStore = defineStore("checkout", {
  state: (): orderState => {
    return {
      forceClosed: false,
      loading: true,
      options: undefined,
      locationVerified: false,
      locationFee: 0,
      order: {
        name: "",
        paymentMethod: 0,
        toggleableFields: {},
        customFields: [],
        deliveryOptions: {
          timeslot: undefined,
          deliver: true,
          deliveryData: {},
        },
        products: [],
        privacy: false,
        tos: false,
      },
    };
  },
  actions: {
    async fetchOptions() {
      const config = useRuntimeConfig();
      const { data, error }: { data: Ref<orderOptions>; error: Ref<any> } =
        await useFetch(config.public.api_url + "/api/v1/order/checkout/data");
      if (error.value != undefined) {
        this.forceClosed = true;
      } else if (data.value) {
        this.options = data.value as orderOptions;
      }
      this.loading = false;
    },
    resetOrder() {
      // FIXME: Implement
      // This should initialize all variables of the order object
      // Reset custom fields to nothing
      this.order.customFields = [];
      // Initialize all custom fields with default values.
      for (const field of this.getOptions.preProductOptions.concat(
        this.getOptions.postProductOptions,
      )) {
        let input: customFieldDto;
        if (field.datatype == "boolean") {
          input = {
            id: field.id,
            value: field.default == "true",
          };
        } else if (field.datatype == "integer") {
          input = {
            id: field.id,
            value: field.default ? parseFloat(field.default) : "",
          };
        } else {
          input = {
            id: field.id,
            value: field.default,
          };
        }
        this.order.customFields.push(input);
      }

      // Reset products to nothing
      this.order.products = [];
      // Initialize the list of products with default values
      for (const product of this.getOptions.productOptions.products) {
        const input = {
          id: product.id,
          amount: 0,
        };
        this.order.products.push(input);
      }
      // FIXME: The list of paymentMethods is first ordered so the highest ID is on the top. This will be replaced by a
      //  priority property
      this.getOptions.paymentMethods.sort((a, b) => {
        return a.id - b.id;
      });

      // Initialize the payment method with the first one of the list
      this.order.paymentMethod = this.getOptions.paymentMethods[0].id;

      // If donations are enabled, the default is 0
      if (this.getOptions.toggleableFields.donation.enabled) {
        this.order.toggleableFields.donation = 0;
      }
    },
    setService(id: number) {
      for (const product of this.order.products) {
        product.amount = product.id == id ? 1 : 0;
      }
    },
    changeProduct(id: number, newQuantity: number) {
      const field: productDto | undefined = this.order.products.find(
        (input) => id == input.id,
      ) as productDto;
      field.amount = newQuantity;
    },
    setCustomField({ id, value }: customFieldDto) {
      // As we preload the array with the default values, we MUST find a value and thus can assume it as true
      const field: customFieldDto = this.order.customFields.find(
        (input) => id == input.id,
      ) as customFieldDto;
      field.value = value;
    },
  },
  getters: {
    getOptions(): orderOptions {
      if (this.loading || this.options == undefined) {
        throw new Error(
          "Requested the order options before initialization! This should not occur and is due to faulty code.",
        );
      }
      return this.options as orderOptions;
    },
    getService(): item | undefined {
      // Determine which service is chosen
      const productId = this.order.products.find((item) => item.amount == 1)
        ?.id;
      return this.getOptions.productOptions.products.find(
        (item) => item.id == productId,
      );
    },
    getAvailableProducts(): item[] {
      // Returns all products
      // If we are in service mode, change the label to include the price
      // Filter items on containing timeslots
      const items = this.getOptions.productOptions.products.filter(
        (value) => value.timeslots.length > 0,
      );

      const mode = this.getOptions.productOptions.mode;
      let result = [] as item[];
      if (mode === "SERVICE") {
        for (const item of items) {
          const entry = JSON.parse(JSON.stringify(item));
          // FIXME: make price a global formatter tool, something like formatPrice();
          entry.label += " - € " + (item.price / 100).toFixed(2);
          result.push(entry);
        }
      } else {
        result = items;
      }

      return result;
    },
    getTotalPrice(): string {
      let sum = 0;
      for (const product of this.order.products) {
        const productPrice =
          this.getOptions.productOptions.products.find(
            (item) => item.id == product.id,
          )?.price ?? 0;
        sum += productPrice * product.amount;
      }

      sum += this.locationFee;

      if (this.order.toggleableFields.donation) {
        // const donation = unref(this.order.toggleableFields.donation).replace(",", ".");
        const donation = unref(this.order.toggleableFields.donation);
        // sum += (parseFloat(donation) ?? 0) * 100;
        // TODO: FIX
        sum += ((donation as number) ?? 0) * 100;
      }

      return "€ " + (sum / 100).toFixed(2);
    },
    getTimeslots(): Array<deliveryDay> {
      // Use this trick to lose reactivity on the object.
      let days = unref(this.getOptions.timeslots) as orderOptions["timeslots"];

      const mode = this.getOptions.productOptions.mode;

      if (mode === "SERVICE") {
        // Get the service information
        const serviceId = this.order.products.find(
          (day: productDto) => day.amount == 1,
        )?.id;
        if (!serviceId) {
          return [];
        }
        const service = this.getOptions.productOptions.products.find(
          (product) => product.id == serviceId,
        ) as item;

        for (const day of days) {
          day.slots = day.slots.filter((slot: deliverySlot) =>
            service.timeslots.includes(slot.id),
          );
        }
      } else {
        // We are in product mode, only if the timeslot is enabled for every
        // product it should be included, but only products which have been selected count.
        const productIds: number[] = [];
        for (const item of this.order.products) {
          if (item.amount > 0) {
            productIds.push(item.id);
          }
        }
        const products = this.getOptions.productOptions.products.filter(
          (item) => {
            return productIds.includes(item.id);
          },
        );

        for (const product of products) {
          const amount =
            this.order.products.find(
              (orderProduct) => orderProduct.id == product.id,
            )?.amount ?? 0;
          if (amount > product.maxCapacity) {
            // If we exceed the maximum capacity available for a product, we will not find any timeslots with more capacity.
            // Return an empty array.
            return [];
          }

          for (const day of days) {
            day.slots = day.slots.filter((slot: deliverySlot) => {
              const capacity = product.capacities.find((capSlot) => {
                return capSlot.timeslot == slot.id;
              });
              return (
                product.timeslots.includes(slot.id) &&
                amount <= (capacity?.capacity ?? Infinity)
              );
            });
          }
        }
      }

      // Remove all days that have no times left
      days = days.filter((day: deliveryDay) => day.slots.length > 0);

      return days;
    },
    getTimeslotMessage(): alert | undefined {
      return this.getTimeslot?.alert;
    },
    getTimeslot(): deliverySlot | undefined {
      // Get the timeslot
      const pickupSlotId = this.order.deliveryOptions.timeslot;
      for (const day of this.getOptions.timeslots) {
        for (const slot of day.slots) {
          if (slot.id == pickupSlotId) {
            return slot as deliverySlot;
          }
        }
      }
      return undefined;
    },
    getOrderDTO(): orderDTO {
      // Stringify and parse to prevent shallow copying
      const order = JSON.parse(JSON.stringify(this.order)) as orderDTO;
      // Dev note: this function should be executed after validation. Everything in this code block
      // assumes sane input!

      // Alter the donation field to be in cents
      if (this.getOptions.toggleableFields.donation.enabled) {
        // TODO: FIX
        order.toggleableFields.donation =
          ((order.toggleableFields.donation as number) ?? 0) * 100;
      }

      if (this.getOptions.toggleableFields.telephone.enabled) {
        if (order.toggleableFields.telephone) {
          order.toggleableFields.telephone =
            "+31" +
            (order.toggleableFields.telephone as string)
              .replaceAll(" ", "")
              .replaceAll("-", "")
              .slice(1);
        }
      }

      if (order.deliveryOptions.deliver) {
        // TODO: Sanitize the postalcode
        order.deliveryOptions.deliveryData.postalCode =
          order.deliveryOptions.deliveryData.postalCode
            ?.replaceAll(" ", "")
            .toUpperCase();

        // Split the housenumber and its suffix
        const sanitizedInput =
          order.deliveryOptions.deliveryData.houseNumber?.replaceAll(" ", "");

        const number = sanitizedInput?.match(/[0-9]{1,3}/g);
        const suffix = sanitizedInput?.match(/[a-zA-Z]{1,3}/g);

        order.deliveryOptions.deliveryData.houseNumber = number
          ? number[0]
          : undefined;
        order.deliveryOptions.deliveryData.houseNumberSuffix = suffix
          ? suffix[0]
          : undefined;
      }

      if (this.getOptions.productOptions.mode == "SERVICE") {
        order.products = order.products.filter((value) => value.amount > 0);
      }

      return order;
    },
    getValidationRules() {
      const rules: any = {};

      const options = this.getOptions;

      // Add rules for the default fields
      rules.name = { dutchRequired };

      rules.privacy = {
        dutchRequired,
        mustBeChecked: helpers.withMessage(
          "Om te kunnen bestellen moet je akkoord gaan met ons privacybeleid.",
          sameAs(true),
        ),
      };
      rules.tos = {
        dutchRequired,
        mustBeChecked: helpers.withMessage(
          "Om te kunnen bestellen moet je akkoord gaan met onze algemene voorwaarden.",
          sameAs(true),
        ),
      };

      // Add rules for the delivery data
      rules.deliveryOptions = {
        timeslot: { dutchRequired },
      };

      if (this.order.deliveryOptions.deliver) {
        rules.deliveryOptions.deliveryData = {
          houseNumber: {
            dutchRequired,
            dutchHouseNumber,
          },
          postalCode: {
            dutchRequired,
            dutchPostalCode,
          },
        };
      } else {
        rules.deliveryOptions.deliveryData = {
          pickupPoint: dutchRequired,
        };
      }

      // Add rules requiring products
      // TODO: Add these validations

      // Add rules for all the toggleablefields
      rules.toggleableFields = {
        email: {
          requiredIf: dutchRequiredIf(options.toggleableFields.email.required),
          dutchEmail,
        },
        telephone: {
          requiredIf: dutchRequiredIf(
            options.toggleableFields.telephone.required,
          ),
          mustBeNumeric: dutchNumeric,
          dutchTelephone,
        },
        donation: {
          mustBePositive: minValue(0),
          dutchNumeric,
          decimal,
        },
        customer_remark: {
          requiredIf: dutchRequiredIf(
            options.toggleableFields.customer_remark.required,
          ),
        },
      };

      return rules;
    },
  },
});

type orderState = {
  forceClosed: boolean;
  loading: boolean;
  locationVerified: boolean;
  locationFee: number;
  options?: orderOptions;
  order: orderDTO;
};
