<template>
  <!--    <div class="text-subtitle-1 text-medium-emphasis">Levermethode</div>-->
  <!--    <v-radio-group-->
  <!--        v-model="checkoutStore.order.deliveryOptions.deliver"-->
  <!--        inline-->
  <!--        mandatory-->
  <!--    >-->
  <!--        <v-radio :value="true" label="Bezorgen" />-->
  <!--        <v-radio :value="false" label="Afhalen" />-->
  <!--    </v-radio-group>-->
  <v-container
    v-if="checkoutStore.order.deliveryOptions.deliver"
    class="pa-0"
    fluid
  >
    <div class="text-subtitle-1 text-medium-emphasis">Adresgegevens</div>
    <v-row>
      <v-col cols="7" sm="4">
        <v-text-field
          v-model.lazy="
            checkoutStore.order.deliveryOptions.deliveryData.postalCode
          "
          :error-messages="getVuelidateErrors(v$.postalCode.$errors)"
          density="compact"
          hide-details="auto"
          placeholder="Postcode"
          prepend-inner-icon="mdi-map-marker"
          variant="outlined"
          @focusout="v$.postalCode.$validate"
          @update:model-value="debounceUpdateLocationInformation"
        />
      </v-col>
      <v-col cols="5" sm="3">
        <v-text-field
          v-model="checkoutStore.order.deliveryOptions.deliveryData.houseNumber"
          :error-messages="getVuelidateErrors(v$.houseNumber.$errors)"
          density="compact"
          hide-details="auto"
          :placeholder="smAndUp ? 'Huisnummer' : 'Huisnr.'"
          variant="outlined"
          @focusout="v$.houseNumber.$validate"
          @update:model-value="debounceUpdateLocationInformation"
        />
      </v-col>
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="streetName"
          :loading="postalCodeLoading"
          density="compact"
          placeholder="Straat - automatisch aangevuld"
          readonly
          variant="outlined"
        />
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <div class="text-subtitle-1 text-medium-emphasis">Afhaalpunt</div>
    <v-select
      v-model="checkoutStore.order.deliveryOptions.deliveryData.pickupPoint"
      :items="checkoutStore.getOptions.pickupPoints"
      density="compact"
      item-title="label"
      item-value="id"
      placeholder="Afhaalpunt"
      variant="outlined"
    />
  </div>
  <template v-if="unsupportedLocationError">
    <v-alert type="error">
      {{ $t("checkout.unsupportedLocation") }}
    </v-alert>
    <br />
  </template>
</template>

<script lang="ts" setup>
import useVuelidate from "@vuelidate/core";
import { useDisplay } from "vuetify";
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";
import { getVuelidateErrors } from "~/utils/errorHandler";

const { smAndUp } = useDisplay();

const checkoutStore = useCheckoutStore();

const v$ = useVuelidate(
  checkoutStore.getValidationRules.deliveryOptions.deliveryData,
  checkoutStore.order.deliveryOptions.deliveryData,
);

const streetName = ref<string>();

const unsupportedLocationError = ref<boolean>();

const deliveryEnabled = ref(true);
const pickupEnabled = computed(() => {
  // return checkoutStore.getOptions.pickupPoints.length > 0;
  return false;
});

const postalCodeLoading = ref(false);

let timer: number | undefined;
const debounceUpdateLocationInformation = () => {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(updateLocationInformation, 500) as unknown as number;
};

const config = useRuntimeConfig();

const updateLocationInformation = async () => {
  checkoutStore.locationVerified = false;
  // TODO: Reset the form
  streetName.value = "";
  unsupportedLocationError.value = false;
  checkoutStore.locationFee = 0;
  let deliveryDataDto;
  if (checkoutStore.order.deliveryOptions.deliver) {
    deliveryDataDto = checkoutStore.order.deliveryOptions.deliveryData;
  } else {
    return;
  }
  const postalCode: RegExpMatchArray | null = (deliveryDataDto.postalCode ?? "")
    .replaceAll(" ", "")
    .match(/[0-9]{4}([a-zA-Z]{2})/g);
  const houseNumber = (deliveryDataDto.houseNumber ?? "")
    .replaceAll(" ", "")
    .match(/[0-9]{1,3}([a-zA-Z]*)/g);
  const suffix = (deliveryDataDto.houseNumber ?? "")
    .replaceAll(" ", "")
    .match(/[a-zA-Z]{1,2}/g);

  if (postalCode && houseNumber) {
    // Check if the postal code is valid
    postalCodeLoading.value = true;
    await fetch(
      config.public.api_url +
        "/api/v1/address/lookup?" +
        new URLSearchParams({
          postalCode: postalCode[0],
          houseNumber: houseNumber[0],
        }),
    )
      .then((response) => {
        if (response.ok) return response.json();
        else {
          throw response.json();
        }
      })
      .then((data) => {
        streetName.value =
          data.street +
          " " +
          data.houseNumber +
          (suffix ? suffix[0] : "") +
          " te " +
          data.city;

        // Update prices of products and the timeslots
        for (const product of checkoutStore.getOptions.productOptions
          .products) {
          const bind = data.products.find(
            (item: { id: number; price: number }) => item.id == product.id,
          );
          if (bind) {
            product.price = bind.price;
            product.timeslots = bind.timeslots;
          } else {
            product.timeslots = [];
          }
        }

        // Configure the location fee
        checkoutStore.locationFee = data.locationFee;

        // show the rest of the form
        checkoutStore.locationVerified = true;

        postalCodeLoading.value = false;
      })
      .catch(async (error: Promise<any>) => {
        const data = await error;
        postalCodeLoading.value = false;
        if (data.error_code == -914) {
          unsupportedLocationError.value = true;
        } else if (data.error_code == -908) {
          streetName.value = "Adres niet gevonden.";
        } else {
          console.error(data);
        }
      });
  } else return "";
};
</script>

<style scoped></style>
