<script lang="ts" setup>
import useVuelidate from "@vuelidate/core";
import { numeric } from "@vuelidate/validators";
import { formatPrice } from "~/composables/formatter";
import type { item } from "~/composables/store/checkout";
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";

const props = defineProps<{
  product: item;
}>();

const checkoutStore = useCheckoutStore();

const fieldModel = ref<string | number>(0);

const v$ = useVuelidate(
  {
    numeric,
  },
  fieldModel,
);

const updateOrder = () => {
  const parsedVal = parseInt(fieldModel.value.toString());
  if (!isNaN(parsedVal)) {
    checkoutStore.changeProduct(props.product.id, parsedVal);
  }
};
</script>

<template>
  <tr>
    <td>{{ props.product.label }}</td>
    <td>{{ formatPrice(props.product.price) }}</td>
    <td style="width: 100px">
      <v-text-field
        v-model="fieldModel"
        density="compact"
        hide-details
        number
        single-line
        variant="outlined"
        :error="v$.$errors.length > 0"
        @focusout="
          () => {
            v$.$validate();
            updateOrder();
          }
        "
      />
    </td>
  </tr>
</template>

<style scoped></style>
