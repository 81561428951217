// function deliveryDataValidator (value, )
import {
  decimal,
  email,
  helpers,
  integer,
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  requiredIf,
} from "@vuelidate/validators";
import type { Ref } from "vue";
import type { ValidationRule, ValidationRuleWithParams } from "@vuelidate/core";

export const dutchRequired: ValidationRule = helpers.withMessage(
  "Dit veld is verplicht.",
  required,
);

export const dutchRequiredIf = (
  cond: string | boolean | Ref<boolean> | (() => boolean | Promise<boolean>),
): ValidationRuleWithParams =>
  helpers.withMessage("Dit veld is verplicht.", requiredIf(cond));

export const dutchNumeric = helpers.withMessage(
  "Gebruik alstublieft alleen getallen.",
  numeric,
);

export const dutchEmail = helpers.withMessage(
  "Vul alstublieft een geldig emailadres in.",
  email,
);

const telephone = (value: string) => {
  const val = value.replace(" ", "");
  return val.length === 10;
};

export const dutchTelephone = helpers.withMessage(
  "Het ingevulde telefoonnummer is ongeldig.",
  telephone,
);

const postalCode = (value: string) =>
  !!value.replaceAll(" ", "").match(/[0-9]{4}([a-zA-Z]{2})/g);

export const dutchPostalCode = helpers.withMessage(
  "De ingevulde postcode is ongeldig.",
  postalCode,
);

const houseNumber = (value: string) =>
  (value.replaceAll(" ", "").match(/[0-9]{1,3}([a-zA-Z]{0,2})/g) ?? []).length >
  0;

export const dutchHouseNumber = helpers.withMessage(
  "Het ingevulde huisnummer is ongeldig.",
  houseNumber,
);

// TODO:
// * integer
// * decimal
// * regex (wtf moet dit worden ook?)

export const dutchInteger = helpers.withMessage(
  "Dit veld moet een geheel getal zijn.",
  integer,
);
export const dutchDecimal = helpers.withMessage(
  "Dit veld moet een getal zijn.",
  decimal,
);

export const dutchMinVal = (minimum: number) =>
  helpers.withMessage(
    ({ $params }) => `Het getal mag niet lager zijn dan ${$params.min}.`,
    minValue(minimum),
  );
export const dutchMaxVal = (maximum: number) =>
  helpers.withMessage(
    ({ $params }) => `Het getal mag niet hoger zijn dan ${$params.max}.`,
    maxValue(maximum),
  );
export const dutchMinLength = (minimum: number) =>
  helpers.withMessage(
    ({ $params }) =>
      `De hoeveelheid tekens mag niet lager zijn dan ${$params.min}.`,
    minLength(minimum),
  );
export const dutchMaxLength = (maximum: number) =>
  helpers.withMessage(
    ({ $params }) =>
      `De hoeveelheid tekens mag niet hoger zijn dan ${$params.max}.`,
    maxLength(maximum),
  );
