<template>
  <v-container fluid>
    <v-row>
      <v-col class="pa-10 d-none d-md-inline" cols="3">
        <content-doc path="sidebar/left" :head="false" />
      </v-col>
      <v-col class="pa-10">
        <div v-if="optionsStore.forceClosed">
          <content-doc path="closed" :head="false" />
        </div>
        <checkout-page-checkout-form v-else-if="!optionsStore.loading" />
      </v-col>
      <v-col class="pa-10 d-none d-md-inline" cols="3">
        <content-doc path="sidebar/right" :head="false" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";
import { useAsyncData } from "#app";

const config = useGlobyConfig();
const nuxtConfig = useRuntimeConfig();

useServerSeoMeta({
  title: `${config.organization.slogan} | Globy`,
  ogTitle: `${config.organization.slogan} | Globy`,
  ogUrl: nuxtConfig.public.base_url,
  description: config.organization.description,
  ogDescription: config.organization.description,
});

onMounted(() => {
  if (!optionsStore.forceClosed) {
    optionsStore.resetOrder();
  }
});

const optionsStore = useCheckoutStore();
await useAsyncData(() => optionsStore.fetchOptions());
</script>
